import React, { useEffect } from 'react';
import { KTIcon } from '../../../../../_metronic/helpers';
import { useAppContext } from '../../../../pages/AppContext/AppContext';

interface Step1Props {
  accountType: string;
  onAccountTypeChange: (value: string) => void;
  onOtherInputChange: (value: string) => void;
}

const Step1: React.FC<Step1Props> = ({ accountType, onAccountTypeChange, onOtherInputChange }) => {
  const isSelected = (type: string) => accountType === type;
  const { appData } = useAppContext();

  return (
    <div>
      <h2 className='fw-bolder d-flex align-items-center text-dark mb-10'>
        Plans
        <i
          className='fas fa-exclamation-circle ms-2 fs-7'
          data-bs-toggle='tooltip'
          title='Billing is issued based on your selected account type'
        ></i>
      </h2>
      <div className="row formcheck mb-4" style={{ paddingLeft: 0 }}>
        <div className="mb-6">
          <input
            type="radio"
            className="btn-check"
            name="accountType"
            value="student"
            id="student"
            checked={isSelected('student')}
            onChange={() => onAccountTypeChange('student')}
          />
          <label className={`card border rounded ${isSelected('student') ? 'bg-light-primary shadow' : 'border-dotted border-dark'}`} htmlFor="student">
            <span className="card-body">
              <h5 className="card-title mb-2 fs-4 fw-bolder d-flex flex-wrap">
                <span>Student&nbsp;</span>
                <span>($7.99&nbsp;</span>
                <small className="text-muted d-block">per month</small>
                <span>)</span>
              </h5>
              <span className="card-text mb-2">
                The {appData.appName ?? "AI Bot"} plan that is perfect for students and individuals. There is no limit to the number of Courses (AI assistants) that you can build. Try it free for 30 days!
              </span>
            </span>
          </label>
        </div>
      </div>
    </div>
  );
};

export default Step1;

