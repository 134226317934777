import React, { useEffect, useState } from 'react';
import '../../../../_metronic/assets/sass/core/components/_keytermsFlashcard.scss';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { keyTerms } from '../api';
import { useAuth } from "../../auth";


interface Flashcard {
  term: string;
  definitions: string;
}

const KeyTerms = () => {
  const { state }: any = useLocation();
  const currentCommunityId = state.currentCommunity;
  const fileId = state.fileId ? state.fileId : null;
  const fileName = state.fileName ? state.fileName : '';
  const courseName = state.courseName ? state.courseName : '';

  const navigate = useNavigate()
  const { setIsBackFromPages, isBackFromPages, setCurrentCommunity } = useAuth()


  const [flashcards, setFlashcards] = useState<Flashcard[]>([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [flipped, setFlipped] = useState(false);
  const [loading, setLoading] = useState(true);

  const fetchFlashcards = async () => {
    try {
      const response = await keyTerms(fileName, fileId, currentCommunityId, courseName);
      if (response && response.data) {
        setFlashcards(response.data);
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching key terms:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchFlashcards();
  }, [fileName, fileId, currentCommunityId]);

  const handleFlip = () => {
    setFlipped(!flipped);
  };

  const handleNext = () => {
    setFlipped(false);
    setCurrentIndex((prevIndex) => (prevIndex + 1) % flashcards.length);
  };

  const handlePrevious = () => {
    setFlipped(false);
    setCurrentIndex((prevIndex) => (prevIndex - 1 + flashcards.length) % flashcards.length);
  };

  if (loading) {
    return (
      <div className="loading-container">
        <div className="loading-content">
          <div className="rocket-icon">🚀</div>
          <p>Rocket is generating flashcards...</p>
        </div>
      </div>
    );
  }

  if (flashcards.length === 0) {
    return (
      <div className="loading-container">
        <div className="loading-content">
          <p>Rocket could not find any key terms to create flashcards</p>
          <p>
            <div className='mt-4'>
              <span className='beta-warning'>This feature is in beta. Please report any feedback or issues to <a href="mailto:support@fetchrocket.com">our support team</a> and thank you!</span>
              <span>&nbsp; &#128526;</span>
            </div>
          </p>
          <button className="btn btn-primary mt-4" onClick={() => navigate(-1)}>Go Back</button>
        </div>
      </div>
    );
  }

  return (
    <div className="keyterms-flashcard">
      <i className="bi bi-x close-btn" onClick={() => navigate(-1)}></i>

      <div className="flashcards-title">Let's Study with Flashcards!</div>

      <div className="alert alert-success course-header">
        <span>Course: <strong>{courseName}</strong>
          <hr></hr>
          File: <strong>{fileName}</strong>
        </span>
      </div>

      {/* Flashcard */}
      <div className={`keyterms-card ${flipped ? 'flipped' : ''}`} onClick={handleFlip}>
        <div className="keyterms-card-inner">
          <div className="keyterms-card-front">
            <span style={{
              fontSize: flashcards[currentIndex]?.term.length > 32 ? '4rem' : '5rem'
            }}>
              {flashcards[currentIndex]?.term}
            </span>
          </div>
          <div className="keyterms-card-back">
            <span style={{
              fontSize: flashcards[currentIndex]?.definitions.length > 100 ? '2rem' : '3rem'
            }}>
              {flashcards[currentIndex]?.definitions}
            </span>
          </div>
        </div>
      </div>

      {/* Flashcard controls */}
      <div className="flashcard-controls d-flex justify-content-center align-items-center">
        <span className='btn btn-success center' onClick={handlePrevious} style={{ width: 200, fontSize: '2rem' }}>
          &lt;&lt; Previous
        </span>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <span style={{ width: 'auto', fontSize: '2rem' }}>
          {currentIndex + 1} / {flashcards.length}
        </span>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <span className='btn btn-success center' onClick={handleNext} style={{ width: 200, fontSize: '2rem' }}>
          Next &gt;&gt;
        </span>
      </div>
      <div className='beta-container mt-4'>
        <span className='beta-warning'>This feature is in beta. Please report any feedback or issues to <a href="mailto:support@fetchrocket.com">our support team</a> and thank you!
          <span>&nbsp; &#128526;</span></span>
      </div>

    </div>
  );
};

export { KeyTerms };
