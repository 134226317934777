import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx';

interface Step3Props {
  companyDetails: {
    companyName?: string;
    phoneNumber?: string;
    orgType?: string;
    mailingAddStreetName?: string;
    mailingAddCityName?: string;
    mailingAddStateName?: string;
    mailingAddZip?: string;
    billingAddStreetName?: string;
    billingAddCityName?: string;
    billingAddStateName?: string;
    billingAddZip?: string;
  };
  onCompanyDetailsChange: (details: { [key: string]: string }) => void;
  checkboxTick: any;
  setCheckboxTick: any;
}

const initialValues = {
  companyName: '',
  phoneNumber: '',
  orgType: '',
  mailingAddStreetName: '',
  mailingAddCityName: '',
  mailingAddStateName: '',
  mailingAddZip: '',
  billingAddStreetName: '',
  billingAddCityName: '',
  billingAddStateName: '',
  billingAddZip: '',
}

const registrationSchema = Yup.object().shape({
  phoneNumber: Yup.string()
    .min(14, 'Minimum 10 numbers')
    .max(14, 'Maximum 10 numbers')
    .required('Company phone number is required'),
  companyName: Yup.string()
    .min(3, 'Minimum 3 characters')
    .max(50, 'Maximum 50 characters')
    .required('Company name is required'),
  orgType: Yup.string()
    .min(3, 'Minimum 3 characters')
    .max(50, 'Maximum 50 characters')
    .required('Organization type is required'),
  mailingAddStreetName: Yup.string()
    .min(3, 'Minimum 3 characters')
    .max(50, 'Maximum 50 characters')
    .required('Street name/number is required'),
  mailingAddCityName: Yup.string()
    .min(3, 'Minimum 3 characters')
    .max(50, 'Maximum 50 characters')
    .required('City is required'),
  mailingAddStateName: Yup.string()
    .min(2, 'Minimum 2 characters')
    .max(50, 'Maximum 50 characters')
    .required('State is required'),
  mailingAddZip: Yup.string()
    .min(5, 'Minimum 5 numbers')
    .max(5, 'Maximum 5 numbers')
    .required('Zip code is required'),
  billingAddStreetName: Yup.string()
    .min(3, 'Minimum 3 characters')
    .max(50, 'Maximum 50 characters')
    .required('Street name/number is required'),
  billingAddCityName: Yup.string()
    .min(3, 'Minimum 3 characters')
    .max(50, 'Maximum 50 characters')
    .required('City is required'),
  billingAddStateName: Yup.string()
    .min(2, 'Minimum 2 characters')
    .max(50, 'Maximum 50 characters')
    .required('State is required'),
  billingAddZip: Yup.string()
    .min(5, 'Minimum 5 numbers')
    .max(5, 'Maximum 5 numbers')
    .required('Zip code is required'),
})

const Step3: React.FC<Step3Props> = ({ companyDetails, onCompanyDetailsChange, checkboxTick, setCheckboxTick }) => {
  const [compPhoneNumb, setCompPhoneNumb] = useState<any>('')
  const [orgType, setOrgType] = useState<string>('')
  const [isOtherSelected, setIsOtherSelected] = useState<boolean>(false)

  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: (values) => {
      // onCompanyDetailsChange(values)
    },
  });

  const handlePhoneNumberChange = (e: any, numbertype: 'Company') => {
    if (numbertype == 'Company') {
      const formattedPhoneNumber = formatPhoneNumber(e.target.value);
      setCompPhoneNumb(formattedPhoneNumber);
    }
  }

  const formatPhoneNumber = (value: string) => {
    if (!value) return value;
    const phoneNumber = value.replace(/[^\d]/g, '');
    const phoneNumberLength = phoneNumber.length;
    if (phoneNumberLength < 4) return phoneNumber;
    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
  }

  useEffect(() => {
    formik.setFieldValue('phoneNumber', compPhoneNumb)
  }, [compPhoneNumb])

  const autoFillBillingAddress = (event: any) => {

    if (event.target.checked) {
      const streetName = formik.getFieldMeta('billingAddStreetName')
      const city = formik.getFieldMeta('billingAddCityName')
      const state = formik.getFieldMeta('billingAddStateName')
      const zipcode = formik.getFieldMeta('billingAddZip')

      if (streetName.value == '' || city.value == '' || state.value == '' || zipcode.value == '') {
        return
      }

      formik.setFieldValue('mailingAddStreetName', streetName.value)
      formik.setFieldValue('mailingAddCityName', city.value)
      formik.setFieldValue('mailingAddStateName', state.value)
      formik.setFieldValue('mailingAddZip', zipcode.value)
      setCheckboxTick(true)
    } else {
      setCheckboxTick(false)
      formik.setFieldValue('mailingAddStreetName', '')
      formik.setFieldValue('mailingAddCityName', '')
      formik.setFieldValue('mailingAddStateName', '')
      formik.setFieldValue('mailingAddZip', '')
    }
  }

  const updateOrgType = (_type: string) => {
    if (_type == 'Other') {
      setOrgType(_type)
      formik.setFieldValue('orgType', '')
      setIsOtherSelected(true)
    } else {
      setIsOtherSelected(false)
      formik.setFieldValue('orgType', _type)
      setOrgType(_type)
    }
  }

  const handleStreetNameChange = (event: any, addressType: 'mailing' | 'billing') => {
    if (addressType == 'billing') {
      if (checkboxTick) {
        formik.setFieldValue('billingAddStreetName', event.target.value)
        formik.setFieldValue('mailingAddStreetName', event.target.value)
      } else {
        formik.setFieldValue('billingAddStreetName', event.target.value)
      }
    } else if (addressType == 'mailing') {
      formik.setFieldValue('mailingAddStreetName', event.target.value)
    }
  }

  const handleCityNameChange = (event: any, addressType: 'mailing' | 'billing') => {
    if (addressType == 'billing') {
      if (checkboxTick) {
        formik.setFieldValue('billingAddCityName', event.target.value)
        formik.setFieldValue('mailingAddCityName', event.target.value)
      } else {
        formik.setFieldValue('billingAddCityName', event.target.value)
      }
    } else if (addressType == 'mailing') {
      formik.setFieldValue('mailingAddCityName', event.target.value)
    }
  }

  const handleStateNameChange = (event: any, addressType: 'mailing' | 'billing') => {
    if (addressType == 'billing') {
      if (checkboxTick) {
        formik.setFieldValue('mailingAddStateName', event.target.value)
        formik.setFieldValue('billingAddStateName', event.target.value)
      } else {
        formik.setFieldValue('billingAddStateName', event.target.value)
      }
    } else if (addressType == 'mailing') {
      formik.setFieldValue('mailingAddStateName', event.target.value)
    }
  }

  const handleZipCodeChange = (event: any, addressType: 'mailing' | 'billing') => {
    if (addressType == 'billing') {
      if (checkboxTick) {
        formik.setFieldValue('mailingAddZip', event.target.value)
        formik.setFieldValue('billingAddZip', event.target.value)
      } else {
        formik.setFieldValue('billingAddZip', event.target.value)
      }
    } else if (addressType == 'mailing') {
      formik.setFieldValue('mailingAddZip', event.target.value)
    }
  }

  useEffect(() => {
    if (formik.isValid) {
      onCompanyDetailsChange(formik.values)
    }
  }, [formik.values, formik.isValid]);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  useEffect(() => {
    if (companyDetails) {
      formik.setFieldValue('companyName', companyDetails.companyName)
      formik.setFieldValue('phoneNumber', companyDetails.phoneNumber)
      formik.setFieldValue('orgType', companyDetails.orgType)
      updateOrgType(companyDetails.orgType ?? "")
      formik.setFieldValue('mailingAddStreetName', companyDetails.mailingAddStreetName)
      formik.setFieldValue('mailingAddCityName', companyDetails.mailingAddCityName)
      formik.setFieldValue('mailingAddStateName', companyDetails.mailingAddStateName)
      formik.setFieldValue('mailingAddZip', companyDetails.mailingAddZip)
      formik.setFieldValue('billingAddStreetName', companyDetails.billingAddStreetName)
      formik.setFieldValue('billingAddCityName', companyDetails.billingAddCityName)
      formik.setFieldValue('billingAddStateName', companyDetails.billingAddStateName)
      formik.setFieldValue('billingAddZip', companyDetails.billingAddZip)
    }
  }, [])

  return (
    <>
      <div>
        <h2 className='fw-bolder d-flex align-items-center text-dark mb-10'>
          Company Details
          <i
            className='fas fa-exclamation-circle ms-2 fs-7'
            data-bs-toggle='tooltip'
            title='Billing is issued based on your selected account type'
          ></i>
        </h2>
        <label
          className={`btn btn-outline btn-outline-default mb-10 p-7 d-flex align-items-center ${formik.isValid ? 'btn-outline-success' : 'btn-outline-dashed'}`}
          htmlFor='kt_create_account_form_account_type_personal'
        >
          <span className='w-100 fw-bold text-start'>
            {/* begin::Form group Company Name */}
            <div className='fv-row mb-8'>
              <label className='form-label fw-bolder text-dark fs-6'>Company / Organization Name</label>
              <input
                placeholder='Company name'
                type='text'
                autoComplete='off'
                {...formik.getFieldProps('companyName')}
                className={clsx(
                  'form-control bg-transparent',
                  { 'is-invalid': formik.touched.companyName && formik.errors.companyName, },
                  { 'is-valid': formik.touched.companyName && !formik.errors.companyName, }
                )}
              />
              {formik.touched.companyName && formik.errors.companyName && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.companyName}</span>
                  </div>
                </div>
              )}
            </div>
            {/* end::Form group */}

            {/* begin::Form group Phone number */}
            <div className='fv-row mb-8'>
              <label className='form-label fw-bolder text-dark fs-6'>Company Phone Number</label>
              <input
                placeholder='Company phone number'
                type='text'
                autoComplete='off'
                {...formik.getFieldProps('phoneNumber')}
                onChange={(e) => handlePhoneNumberChange(e, 'Company')}
                className={clsx(
                  'form-control bg-transparent',
                  { 'is-invalid': formik.touched.phoneNumber && formik.errors.phoneNumber, },
                  { 'is-valid': formik.touched.phoneNumber && !formik.errors.phoneNumber, }
                )}
              />
              {formik.touched.phoneNumber && formik.errors.phoneNumber && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.phoneNumber}</span>
                  </div>
                </div>
              )}
            </div>
            {/* end::Form group */}

            {/*begin::Form Group */}
            <div className='fv-row mb-8'>
              <label className='form-label fw-bolder text-dark fs-6'>Company / Organization Type</label>
              <div>
                {/*begin:Option */}
                <label className='d-flex align-items-center justify-content-between mb-6 cursor-pointer'>
                  <span className='d-flex align-items-center me-2'>
                    <span className='d-flex flex-column'>
                      <span className='fw-bold text-muted fs-6'>Company</span>
                    </span>
                  </span>

                  <span className='form-check form-check-custom form-check-solid'>
                    <input
                      className='form-check-input'
                      type='radio'
                      name='orgType'
                      value='Company'
                      checked={orgType === 'Company'}
                      onChange={() => updateOrgType('Company')}
                    />
                  </span>
                </label>
                {/*end::Option */}

                {/*begin:Option */}
                <label className='d-flex align-items-center justify-content-between mb-6 cursor-pointer'>
                  <span className='d-flex align-items-center me-2'>
                    <span className='d-flex flex-column'>
                      <span className='fw-bold text-muted fs-6'>Non Profit</span>
                    </span>
                  </span>

                  <span className='form-check form-check-custom form-check-solid'>
                    <input
                      className='form-check-input'
                      type='radio'
                      name='orgType'
                      value='Non Profit'
                      checked={orgType === 'Non Profit'}
                      onChange={() => updateOrgType('Non Profit')}
                    />
                  </span>
                </label>
                {/*end::Option */}

                {/*begin:Option */}
                <label className='d-flex align-items-center justify-content-between mb-6 cursor-pointer'>
                  <span className='d-flex align-items-center me-2'>
                    <span className='d-flex flex-column'>
                      <span className='fw-bold text-muted fs-6'>Other</span>
                    </span>
                  </span>

                  <span className='form-check form-check-custom form-check-solid'>
                    <input
                      className='form-check-input'
                      type='radio'
                      name='appType'
                      value='Other'
                      checked={isOtherSelected}
                      onChange={() => updateOrgType('Other')}
                    />
                  </span>
                </label>
                {/*end::Option */}

                {isOtherSelected &&
                  <>
                    <input
                      type='text'
                      placeholder='Organization type'
                      autoComplete='off'
                      {...formik.getFieldProps('orgType')}
                      className={clsx(
                        'mt-2 form-control bg-transparent',
                        { 'is-invalid': formik.touched.orgType && formik.errors.orgType, },
                        { 'is-valid': formik.touched.orgType && !formik.errors.orgType, }
                      )}
                    />
                    <>
                      {formik.touched.orgType && formik.errors.orgType && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.orgType}</span>
                          </div>
                        </div>
                      )}
                    </>
                  </>
                }

              </div>
            </div>
            {/*end::Form Group */}

            <label
              className='btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center mb-10'
              htmlFor='kt_create_account_form_account_type_personal'
            >
              <span className='w-100 fw-bold text-start'>
                <span className='text-dark fw-bolder d-block fs-4 mb-5'>Billing Address</span>
                {/* begin::Form group Street Name */}
                <div className='fv-row mb-8'>
                  <label className='form-label fw-bolder text-dark fs-6'>Street Name</label>
                  <input
                    placeholder='Street name'
                    type='text'
                    autoComplete='off'
                    {...formik.getFieldProps('billingAddStreetName')}
                    onChange={(e) => handleStreetNameChange(e, 'billing')}
                    className={clsx(
                      'form-control bg-transparent',
                      { 'is-invalid': formik.touched.billingAddStreetName && formik.errors.billingAddStreetName, },
                      { 'is-valid': formik.touched.billingAddStreetName && !formik.errors.billingAddStreetName, }
                    )}
                  />
                  {formik.touched.billingAddStreetName && formik.errors.billingAddStreetName && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.billingAddStreetName}</span>
                      </div>
                    </div>
                  )}
                </div>
                {/* end::Form group */}
                {/* begin::Form group Street Name */}
                <div className='fv-row mb-8'>
                  <label className='form-label fw-bolder text-dark fs-6'>City</label>
                  <input
                    placeholder='City name'
                    type='text'
                    autoComplete='off'
                    {...formik.getFieldProps('billingAddCityName')}
                    onChange={(e) => handleCityNameChange(e, 'billing')}
                    className={clsx(
                      'form-control bg-transparent',
                      { 'is-invalid': formik.touched.billingAddCityName && formik.errors.billingAddCityName, },
                      { 'is-valid': formik.touched.billingAddCityName && !formik.errors.billingAddCityName, }
                    )}
                  />
                  {formik.touched.billingAddCityName && formik.errors.billingAddCityName && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.billingAddCityName}</span>
                      </div>
                    </div>
                  )}
                </div>
                {/* end::Form group */}
                {/* begin::Form group Street Name */}
                <div className='fv-row mb-8'>
                  <label className='form-label fw-bolder text-dark fs-6'>State</label>
                  <select
                    value={formik.getFieldMeta('billingAddStateName').value}
                    className='form-select form-select-lg form-select-solid'
                    name='states'
                    onChange={(e) => handleStateNameChange(e, 'billing')}
                  >
                    <option value="">Select a State</option>
                    <option value="AL">Alabama</option>
                    <option value="AK">Alaska</option>
                    <option value="AZ">Arizona</option>
                    <option value="AR">Arkansas</option>
                    <option value="CA">California</option>
                    <option value="CO">Colorado</option>
                    <option value="CT">Connecticut</option>
                    <option value="DE">Delaware</option>
                    <option value="DC">District Of Columbia</option>
                    <option value="FL">Florida</option>
                    <option value="GA">Georgia</option>
                    <option value="HI">Hawaii</option>
                    <option value="ID">Idaho</option>
                    <option value="IL">Illinois</option>
                    <option value="IN">Indiana</option>
                    <option value="IA">Iowa</option>
                    <option value="KS">Kansas</option>
                    <option value="KY">Kentucky</option>
                    <option value="LA">Louisiana</option>
                    <option value="ME">Maine</option>
                    <option value="MD">Maryland</option>
                    <option value="MA">Massachusetts</option>
                    <option value="MI">Michigan</option>
                    <option value="MN">Minnesota</option>
                    <option value="MS">Mississippi</option>
                    <option value="MO">Missouri</option>
                    <option value="MT">Montana</option>
                    <option value="NE">Nebraska</option>
                    <option value="NV">Nevada</option>
                    <option value="NH">New Hampshire</option>
                    <option value="NJ">New Jersey</option>
                    <option value="NM">New Mexico</option>
                    <option value="NY">New York</option>
                    <option value="NC">North Carolina</option>
                    <option value="ND">North Dakota</option>
                    <option value="OH">Ohio</option>
                    <option value="OK">Oklahoma</option>
                    <option value="OR">Oregon</option>
                    <option value="PA">Pennsylvania</option>
                    <option value="RI">Rhode Island</option>
                    <option value="SC">South Carolina</option>
                    <option value="SD">South Dakota</option>
                    <option value="TN">Tennessee</option>
                    <option value="TX">Texas</option>
                    <option value="UT">Utah</option>
                    <option value="VT">Vermont</option>
                    <option value="VA">Virginia</option>
                    <option value="WA">Washington</option>
                    <option value="WV">West Virginia</option>
                    <option value="WI">Wisconsin</option>
                    <option value="WY">Wyoming</option>
                  </select>
                </div>
                {/* end::Form group */}
                {/* begin::Form group Street Name */}
                <div className='fv-row mb-8'>
                  <label className='form-label fw-bolder text-dark fs-6'>Zipcode</label>
                  <input
                    placeholder='Zipcode'
                    type='text'
                    autoComplete='off'
                    {...formik.getFieldProps('billingAddZip')}
                    onChange={(e) => handleZipCodeChange(e, 'billing')}
                    className={clsx(
                      'form-control bg-transparent',
                      { 'is-invalid': formik.touched.billingAddZip && formik.errors.billingAddZip, },
                      { 'is-valid': formik.touched.billingAddZip && !formik.errors.billingAddZip, }
                    )}
                  />
                  {formik.touched.billingAddZip && formik.errors.billingAddZip && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.billingAddZip}</span>
                      </div>
                    </div>
                  )}
                </div>
                {/* end::Form group */}
              </span>
            </label>

            <label
              className='btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center mb-10'
              htmlFor='kt_create_account_form_account_type_personal'
            >
              <span className='w-100 fw-bold text-start'>
                <span className='text-dark fw-bolder d-block fs-4 mb-5'>Mailing Address</span>

                <div className='fv-row mb-8'>
                  <input
                    className="form-check-input me-5"
                    onChange={(e) => autoFillBillingAddress(e)}
                    type="checkbox"
                    checked={checkboxTick}
                  />
                  <label className='form-label fw-bolder text-dark fs-6'>Same as billing address</label>
                </div>

                {!checkboxTick &&
                  <>
                    {/* begin::Form group Street Name */}
                    <div className='fv-row mb-8'>
                      <label className='form-label fw-bolder text-dark fs-6'>Street Name</label>
                      <input
                        placeholder='Street name'
                        type='text'
                        autoComplete='off'
                        {...formik.getFieldProps('mailingAddStreetName')}
                        onChange={(e) => handleStreetNameChange(e, 'mailing')}
                        className={clsx(
                          'form-control bg-transparent',
                          { 'is-invalid': formik.touched.mailingAddStreetName && formik.errors.mailingAddStreetName, },
                          { 'is-valid': formik.touched.mailingAddStreetName && !formik.errors.mailingAddStreetName, }
                        )}
                      />
                      {formik.touched.mailingAddStreetName && formik.errors.mailingAddStreetName && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.mailingAddStreetName}</span>
                          </div>
                        </div>
                      )}
                    </div>
                    {/* end::Form group */}
                    {/* begin::Form group Street Name */}
                    <div className='fv-row mb-8'>
                      <label className='form-label fw-bolder text-dark fs-6'>City</label>
                      <input
                        placeholder='City name'
                        type='text'
                        autoComplete='off'
                        {...formik.getFieldProps('mailingAddCityName')}
                        onChange={(e) => handleCityNameChange(e, 'mailing')}
                        className={clsx(
                          'form-control bg-transparent',
                          { 'is-invalid': formik.touched.mailingAddCityName && formik.errors.mailingAddCityName, },
                          { 'is-valid': formik.touched.mailingAddCityName && !formik.errors.mailingAddCityName, }
                        )}
                      />
                      {formik.touched.mailingAddCityName && formik.errors.mailingAddCityName && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.mailingAddCityName}</span>
                          </div>
                        </div>
                      )}
                    </div>
                    {/* end::Form group */}
                    {/* begin::Form group Street Name */}
                    <div className='fv-row mb-8'>
                      <label className='form-label fw-bolder text-dark fs-6'>State</label>
                      <select
                        value={formik.getFieldMeta('mailingAddStateName').value}
                        className='form-select form-select-lg form-select-solid'
                        name='states'
                        onChange={(e) => handleStateNameChange(e, 'mailing')}
                      >
                        <option value="">Select a State</option>
                        <option value="AL">Alabama</option>
                        <option value="AK">Alaska</option>
                        <option value="AZ">Arizona</option>
                        <option value="AR">Arkansas</option>
                        <option value="CA">California</option>
                        <option value="CO">Colorado</option>
                        <option value="CT">Connecticut</option>
                        <option value="DE">Delaware</option>
                        <option value="DC">District Of Columbia</option>
                        <option value="FL">Florida</option>
                        <option value="GA">Georgia</option>
                        <option value="HI">Hawaii</option>
                        <option value="ID">Idaho</option>
                        <option value="IL">Illinois</option>
                        <option value="IN">Indiana</option>
                        <option value="IA">Iowa</option>
                        <option value="KS">Kansas</option>
                        <option value="KY">Kentucky</option>
                        <option value="LA">Louisiana</option>
                        <option value="ME">Maine</option>
                        <option value="MD">Maryland</option>
                        <option value="MA">Massachusetts</option>
                        <option value="MI">Michigan</option>
                        <option value="MN">Minnesota</option>
                        <option value="MS">Mississippi</option>
                        <option value="MO">Missouri</option>
                        <option value="MT">Montana</option>
                        <option value="NE">Nebraska</option>
                        <option value="NV">Nevada</option>
                        <option value="NH">New Hampshire</option>
                        <option value="NJ">New Jersey</option>
                        <option value="NM">New Mexico</option>
                        <option value="NY">New York</option>
                        <option value="NC">North Carolina</option>
                        <option value="ND">North Dakota</option>
                        <option value="OH">Ohio</option>
                        <option value="OK">Oklahoma</option>
                        <option value="OR">Oregon</option>
                        <option value="PA">Pennsylvania</option>
                        <option value="RI">Rhode Island</option>
                        <option value="SC">South Carolina</option>
                        <option value="SD">South Dakota</option>
                        <option value="TN">Tennessee</option>
                        <option value="TX">Texas</option>
                        <option value="UT">Utah</option>
                        <option value="VT">Vermont</option>
                        <option value="VA">Virginia</option>
                        <option value="WA">Washington</option>
                        <option value="WV">West Virginia</option>
                        <option value="WI">Wisconsin</option>
                        <option value="WY">Wyoming</option>
                      </select>
                    </div>
                    {/* end::Form group */}
                    {/* begin::Form group Street Name */}
                    <div className='fv-row mb-8'>
                      <label className='form-label fw-bolder text-dark fs-6'>Zipcode</label>
                      <input
                        placeholder='Zipcode'
                        type='text'
                        autoComplete='off'
                        {...formik.getFieldProps('mailingAddZip')}
                        onChange={(e) => handleZipCodeChange(e, 'mailing')}
                        className={clsx(
                          'form-control bg-transparent',
                          { 'is-invalid': formik.touched.mailingAddZip && formik.errors.mailingAddZip, },
                          { 'is-valid': formik.touched.mailingAddZip && !formik.errors.mailingAddZip, }
                        )}
                      />
                      {formik.touched.mailingAddZip && formik.errors.mailingAddZip && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.mailingAddZip}</span>
                          </div>
                        </div>
                      )}
                    </div>
                    {/* end::Form group */}
                  </>
                }
              </span>
            </label>
          </span>
        </label>
      </div>
    </>
  );
};

export default Step3;
