export const ActiveDirectoryPath = (props: any) => {
    return (
        <div className="d-flex flex-wrap">
            {props.folderTree.map((folder: any) => (
                <>
                {folder.name !=="Root" && 
                    <span
                         className='text-hover-primary cursor-pointer'
                        onClick={() => {
                            props.setCurrentParent(folder.id)
                            localStorage.setItem('current-parent', folder.id);
                        }}
                    >
                        {folder.name}
                    </span>
                }
                    <span className="fw-bold mx-1">/</span>
                </>
            ))}
        </div>
    )
}