import React, { useState, useRef, useEffect } from 'react';
import { useAuth } from '../../auth';
import { getAllCourses, getAllFolders, uploadAudio, getRecordingPromptTime, getRecordingLimit } from '../api';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { FormattedMessage } from 'react-intl';
import { KTIcon } from '../../../../_metronic/helpers';

const AudioRecorder = () => {
  const [recording, setRecording] = useState(false);
  const [audioChunks, setAudioChunks] = useState<Blob[]>([]);
  const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder | undefined>(undefined);
  const [duration, setDuration] = useState(0);
  const [recordingPromptTime, setRecordingPromptTime] = useState<number>(1);
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const [showModal, setShowModal] = useState(false);
  const [recordingName, setRecordingName] = useState('');
  const [showPromptModal, setShowPromptModal] = useState(false);
  const responseTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const { currentCommunity, auth, communityList } = useAuth();
  const [uploading, setUploading] = useState<boolean>(false);
  const [foldersList, setFoldersList] = useState<any[]>([]);
  const [folder, setFolder] = useState<string | undefined>('4');
  const [courses, setCourses] = useState<any>([]);
  const [course, setCourse] = useState<string | undefined>(undefined);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [showStartRecordingPrompt, setShowStartRecordingPrompt] = useState(false);
  const [currentRecordCount,setCurrentRecordCount] = useState<number>(0)
  const [recordLimit,setRecordLimit] = useState<number>(0)
  const [paused, setPaused] = useState(false);

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (recording) {
        const message = 'Recording is ongoing. Are you sure you want to leave?';
        event.returnValue = message;
        return message;
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [recording]);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;

    const promptUser = (delay: number) => {
      timeoutId = setTimeout(() => {
        setShowPromptModal(true);
        responseTimeoutRef.current = setTimeout(() => {
          handlePromptResponse(false);
        }, 120  * 60 *  1000);  
        promptUser(30 * 60 * 1000)  
      }, delay);
    };

    if (recording) {
      promptUser(recordingPromptTime * 60 * 1000);
    }

    return () => {
      clearTimeout(timeoutId);
      if (responseTimeoutRef.current) {
        clearTimeout(responseTimeoutRef.current);
      }
    };
  }, [recording, recordingPromptTime]);

  const generateRecordingName = () => {
    const now = new Date();
    const formattedDate = now.toLocaleDateString().replace(/\//g, '-'); // Format date only
    return `New recording ${formattedDate}`;
  };

  useEffect(() => {
    if (showModal) {
      // Set a default name whenever the modal opens
      setRecordingName(generateRecordingName());
    }
  }, [showModal]);

  const startRecording = () => {
    setShowModal(true);
  };

  const handleStartRecording = (e:any) => {
    e.preventDefault();
    if(course  && recordingName !== ''){    
      setShowModal(false);
      navigator.mediaDevices.getUserMedia({ audio: true })
        .then(stream => {
          const recorder = new MediaRecorder(stream);
          recorder.ondataavailable = e => {
            setAudioChunks(prev => [...prev, e.data]);
          };
          recorder.onstart = () => {
            setDuration(0);
            timerRef.current = setInterval(() => {
              setDuration(prev => prev + 1000);
            }, 1000);
          };
          recorder.start();
          setMediaRecorder(recorder);
          setRecording(true);
          setShowStartRecordingPrompt(true);
          setPaused(false);
        })
        .catch(err => console.error('Error accessing microphone:', err));
    } else {
      setErrorMessage("Fill all details");
    }
  };

  const stopRecording = () => {
    if (mediaRecorder && mediaRecorder.state !== 'inactive') {
      mediaRecorder.stop();
      setRecording(false);
      setDuration(0);
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
      if (mediaRecorder.stream) {
        mediaRecorder.stream.getTracks().forEach(track => track.stop());
      }
    }
  };


  const pauseRecording = () => {
    if (mediaRecorder && mediaRecorder.state === 'recording') {
      mediaRecorder.pause();
      setPaused(true);
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    }
  };

  const resumeRecording = () => {
    if (mediaRecorder && mediaRecorder.state === 'paused') {
      mediaRecorder.resume();
      setPaused(false);
      timerRef.current = setInterval(() => {
        setDuration(prev => prev + 1000);
      }, 1000);
    }
  };

  const handleChange = async function () {
    setUploading(true)
    let formData = new FormData()
    const audioFile = new Blob(audioChunks, { type: 'audio/wav' });
    formData.append('file', audioFile)
    formData.append('parentId', folder ? folder : '')
    formData.append('communityId', course? course : '')
    const date = new Date();
    const fileName = `${recordingName}.wav`;

    uploadAudio(course, folder, fileName, formData)
      .then((response) => {
        if (response.data.success) {
          setAudioChunks([])
          setUploading(false)
          window.location.reload();
        } else {
          setAudioChunks([])
          setUploading(false)
        }
      })
      .catch((err) => {
        console.log(err)
        setAudioChunks([])
        setUploading(false)
      })
      setRecordingName('')
  };

  useEffect(() => {
    if (audioChunks.length > 0) {
      handleChange()
    }
  }, [audioChunks])

  useEffect(() => {
    setTimeout(() => {
      if(errorMessage!=='')
      setErrorMessage('');
    }, 5000);
  }, [errorMessage]);


  const formatDuration = (milliseconds: number) => {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  useEffect(() => {
    if (course) {
      getAllFolders(course).then(res => {
        setFoldersList(res.data.folders);
      });
    }
  }, [course]);

  useEffect(() => {
    setCourses(communityList);
      if(communityList[0]){
        setCourse(communityList[0].id)
      }
  }, [communityList]);

  const handlePromptResponse = (response: boolean) => {
    setShowPromptModal(false);
    if (response) {
      if (responseTimeoutRef.current) {
        clearTimeout(responseTimeoutRef.current);
      }
      resumeRecording();
    } else {
      stopRecording();
    }
  };

  useEffect(() => {
    getRecordingPromptTime().then(res => {
      setRecordingPromptTime(Number(res.data));
    }).catch(err => {
      console.log(err);
    });
  }, []);

  useEffect(()=>{
    if(showModal){
    getRecordingLimit(course).then(res =>{
      setCurrentRecordCount(res.data.count)
      setRecordLimit(res.data.limit)
    }).catch(err =>{
      console.log(err)
    })
  }
  },[showModal])

  const containerStyle: React.CSSProperties = {
    backgroundColor: 'transparent',
    padding: '10px 20px',
    border: '2px solid #ffffff',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    margin: '20px 0',
  };
  
  const indicatorStyle: React.CSSProperties = {
    height: '12px',
    width: '12px',
    display: 'inline-block', 
    verticalAlign: 'middle', 
    position: 'relative',
    right: '3px', 
  };  
  
  const buttonStyle: React.CSSProperties = {
    backgroundColor: 'transparent',
    border: 'none',
    marginLeft: '5px',
    cursor: 'pointer',
  };

  return (
    <div className='d-flex cursor-pointer px-5'>
      {!recording && audioChunks.length === 0 && (
        <div>
         {communityList.length === 0 ? (
            <span className={`menu-title text-white`}></span>
          ) : (
            <>
              <span onClick={startRecording} className={`menu-title text-white`}>
                <i className="bi bi-mic text-white fs-4 me-2"></i>Record
              </span>
            </>
          )}
        </div>
       )}
      {recording && (
  <div
    className="d-flex align-items-center justify-content-center"
    style={containerStyle}
  >
    <span className="d-flex align-items-center">
      {/* Blinking recording indicator */}
      <span
        className="bullet bullet-dot bg-danger animation-blink"
        style={indicatorStyle}
      />
      {/* Recording text and duration */}
      <span className="menu-title text-white ms-3 fw-bold">Recording</span>
      <span className="text-white ms-2" style={{ display: 'inline-block', minWidth: '40px', textAlign: 'center' }}>{formatDuration(duration)}</span>

      {/* Pause/Resume Button */}
      <button
        onClick={paused ? resumeRecording : pauseRecording}
        style={buttonStyle}
      >
        <i
          className={`bi ${
            paused
              ? 'bi-play-circle-fill text-white'
              : 'bi-pause-circle-fill text-white'
          } fs-1`}
        ></i>
      </button>

      {/* Stop Button */}
      <button onClick={stopRecording} style={buttonStyle}>
        <i className="bi bi-stop-circle-fill text-white fs-1"></i>
      </button>
    </span>
  </div>
)}
      {uploading && (
        <div>
          <span className='spinner-border spinner-border-sm align-middle me-2 text-white'></span>
          <span className={`menu-title text-white`}>Uploading</span>
        </div>
      )}
      <Modal show={showModal} onHide={() => setShowModal(false)} id='create_community_modal'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-900px'
        backdrop={true}>
        <div className='modal-header'>
              <h2>
              <FormattedMessage id='DOCUMENTS.START_RECORDING' />
              </h2>
              <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={()=>{setShowModal(false)}} data-bs-toggle="tooltip" title="Close">
                <KTIcon className='fs-1' iconName='cross' />
              </div>
            </div>
        <form onSubmit={handleStartRecording}> 
          <Modal.Body>
            <div className="form-group my-2 px-5">
              <p className='fw-bolder text-dark fs-4 text-end my-2'>
                <FormattedMessage id='COMPANY.PROFILE.USAGE.CURRENT_MONTH_USAGE' /> :  {currentRecordCount} / {recordLimit} <FormattedMessage id='DOCUMENTS.RECORDINGS' />
              </p>
              <label htmlFor="recordingName" className='fw-bolder text-dark fs-4 required my-2'>
                <FormattedMessage id='DOCUMENTS.RECORDING_NAME' />
              </label>
              <input
                type="text"
                placeholder='Recording Name'
                className="form-control"
                id="recordingName"
                value={recordingName}
                onChange={(e) => setRecordingName(e.target.value)}
                required
              />
            </div>
            <div className="form-group my-2 p-5">
              <label htmlFor="course" className='fw-bolder text-dark fs-4 my-2'>Course</label>
              <select
                className="form-select"
                id="course"
                value={course}
                onChange={(e) => setCourse(e.target.value)}
                required
              >
                {courses.map((course: any) => (
                  <option key={course.id} value={course.id}>
                    {course.community_name}
                  </option>
                ))}
              </select>
            </div>
            {course && (
              <div className="form-group my-2 px-5 pb-5">
                <label htmlFor="collection" className='fw-bolder text-dark fs-4'>Folder</label>
                <select
                  className="form-select"
                  id="collection"
                  value={folder}
                  onChange={(e) => setFolder(e.target.value)}
                  required
                >
                  <option value="4">Select a Folder</option>
                  {foldersList.map((folder) => (
                    <option key={folder.id} value={folder.id}>
                      {folder.name}
                    </option>
                  ))}
                </select>
              </div>
            )}
            {errorMessage !== '' && (
              <p className='text-center text-danger mt-5 p-1 border border-danger rounded'>
                {errorMessage}
              </p>
            )}
          </Modal.Body>
          <Modal.Footer className='d-flex justify-content-center w-100'>
            <Button variant="primary" type='submit' className='w-50' disabled={recordingName=='' || !course || currentRecordCount >= recordLimit }>
              Start Recording
            </Button>
          </Modal.Footer>
        </form>
      </Modal>

      <Modal show={showPromptModal} onHide={() => handlePromptResponse(false)}>
        <Modal.Header >
          <Modal.Title>Continue Recording?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormattedMessage id='DOCUMENTS.RECORDING_PROMPT_1' /> {formatDuration(duration)}<FormattedMessage id='DOCUMENTS.RECORDING_PROMPT_2' />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => handlePromptResponse(false)}>
            No
          </Button>
          <Button variant="primary" onClick={() => handlePromptResponse(true)}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showStartRecordingPrompt} onHide={() => setShowStartRecordingPrompt(false)}>
        <Modal.Header>
          <Modal.Title>
            <span className='fw-bolder text-dark fs-3'>
              Recording Started
            </span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <span className='fw-bolder text-dark fs-4'>
            <FormattedMessage id='DOCUMENTS.RECORDING_INSTRUCTION' />
          </span>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => setShowStartRecordingPrompt(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export { AudioRecorder };
