import {createContext, ReactNode, useContext, useEffect, useState} from 'react'
import {QuizHistory} from '../types'

interface QuizContextProps {
  quizHistories: QuizHistory[]
  latestQuiz: QuizHistory | null
  setQuizHistories: (quizzes: QuizHistory[]) => void
  setLatestQuiz: (quiz: QuizHistory) => void
  updateQuizPerformance: (quizId: number, score: number, time: number) => void
}

const QuizContext = createContext<QuizContextProps | undefined>(undefined)

export const QuizProvider = ({children}: {children: ReactNode}) => {
  const [quizHistories, setQuizHistories] = useState<QuizHistory[]>(() => {
    const savedQuizzes = localStorage.getItem('quizHistories')
    return savedQuizzes ? JSON.parse(savedQuizzes) : []
  })
  const [latestQuiz, setLatestQuiz] = useState<QuizHistory | null>(null)

  // update local storage when quizHistories change
  useEffect(() => {
    localStorage.setItem('quizHistories', JSON.stringify(quizHistories))
  }, [quizHistories])

  const updateQuizPerformance = (quizId: number, score: number, time: number) => {
    setQuizHistories((prevHistories) =>
      prevHistories.map((quiz) => (quiz.id === quizId ? {...quiz, score, time} : quiz))
    )
  }

  return (
    <QuizContext.Provider
      value={{quizHistories, latestQuiz, setQuizHistories, setLatestQuiz, updateQuizPerformance}}
    >
      {children}
    </QuizContext.Provider>
  )
}

export const useQuizContext = () => {
  const context = useContext(QuizContext)
  if (!context) {
    throw new Error('useQuizContext must be used within a QuizProvider')
  }
  return context
}
