import React, { useState, useEffect } from 'react';
import './loader.css';
import { useAuth } from '../auth'
import { useThemeMode } from '../../../_metronic/partials/layout/theme-mode/ThemeModeProvider';
import { toAbsoluteUrl } from '../../../_metronic/helpers/AssetHelpers';

const Loader: React.FC = () => {
  const { auth } = useAuth();
  const [dots, setDots] = useState('...');
  const { mode } = useThemeMode()
  const messages = [
    {
      bold: 'Every time you use Rocket, you help pets find homes',
      regular: 'We pledge to donate a portion of our profits to support pet adoptions.',
    },
    {
      bold: 'Did you know you can take notes without typing?',
      regular: 'Rocket takes voice-activated notes, so you can focus in class – not your keyboard.',
    },
    {
      bold: 'Did you know you can summarize without reading?',
      regular: 'Rocket summarizes your files, so you can get key points and save time reading.',
    },
    {
      bold: 'Did you know you can study without stressing?',
      regular: 'Rocket answers your questions like a professor, so you can learn at your own pace.',
    },
    {
      bold: 'Did you know you can practice with Flashcards?',
      regular: 'Rocket can help you prepare with Flashcards for any file, helping you prepare for exams.',
    },
    {
      bold: 'Did you know you can organize with ease?',
      regular: 'Rocket keeps you organized, and manages your courses in one place.',
    },
    {
      bold: 'Did you know you can share with friends?',
      regular: 'Rocket lets you share notes, and makes studying more collaborative.',
    }
  ];
  const [randomMessage, setRandomMessage] = useState(messages[0]);
  useEffect(() => {
    const randomIndex = Math.floor(Math.random() * messages.length);
    setRandomMessage(messages[randomIndex]);
  }, []);




  useEffect(() => {
    const interval = setInterval(() => {
      setDots((prevDots) => (prevDots.length < 3 ? prevDots + '.' : ''));
    }, 500);

    return () => clearInterval(interval);
  }, []);
  return (
    <div id="imgbox" className={mode === 'light' ? 'light-mode' : 'dark-mode'}>
      <div id="loading">
        <img
          src={toAbsoluteUrl('/media/logos/blink.gif')}
          alt="Company Logo"
          id="loadingImage"
        />
      </div>
      <div className="text-container">
        <div className="fetching-message">
          Great to see you again, {auth?.user?.firstname}! Rocket is getting things ready for your
          <h1>
            &nbsp;{dots}&nbsp;
          </h1>
        </div>

        <div className="marketing-text">
          <p className="centered-message">
            <strong>{randomMessage.bold}</strong>
            <br />
            {randomMessage.regular}
          </p>
        </div>
      </div>
    </div>
  );
};


export default Loader;