import React, { createContext, useContext, useState, FC, Dispatch, SetStateAction, useEffect } from 'react';
import { WithChildren } from '../../../_metronic/helpers';
import { getNotifications } from '../document-management/api';

type Notification = {
  id: number;
  name: string;
  message: 'uploading' | 'failed' | 'successfull';
  type:string;
  isViewed:boolean;
  jobId:number;
};

type NotificationsContextProps = {
  notifications: Notification[];
  fetchNotifications:()=>void;
  // addNotification: (name: string, message: 'uploading' | 'failed' | 'successfull',type:string) => void;
  removeNotification: (id: number) => void;
};



const initNotificationsContextPropsState = {
  notifications: [],
  // addNotification: () => {},
  fetchNotifications:()=>{},
  removeNotification: () => {},
};

const NotificationsContext = createContext<NotificationsContextProps>(initNotificationsContextPropsState);

const useNotifications = () => {
  return useContext(NotificationsContext);
};

const NotificationsProvider: FC<WithChildren> = ({ children }) => {

  const [notifications, setNotifications] = useState<Notification[]>([]);
  const fetchNotifications = async () => {
    try {
      const response = await getNotifications(); // Adjust the URL as needed
      console.log(response.data)
      setNotifications(response.data);
    } catch (error) {
      console.error('Failed to fetch notifications', error);
    }
  };

  useEffect(() => {
    // Fetch initial state from the backend
  

    fetchNotifications();
  }, []);

  const addNotification = (name: string, message: 'uploading' | 'failed' | 'successfull',type:string,id:number,isViewed:boolean,jobId:number) => {
    const newNotification: Notification = {
      // id: Date.now(),
      name,
      message,
      type,
      isViewed,
      id,
      jobId
    };
    setNotifications((prev) => [...prev, newNotification]);
  };

  

  const removeNotification = () => {
  // const removeNotification = (id: number) => {
    // setNotifications((prev) => prev.filter((notification) => notification.id !== id));
    setNotifications([])
  };

  return (
    <NotificationsContext.Provider value={{ notifications, removeNotification,fetchNotifications }}>
      {children}
    </NotificationsContext.Provider>
  );
};

export { NotificationsProvider, useNotifications };