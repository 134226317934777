import { FormattedMessage } from "react-intl"
import { KTIcon, toAbsoluteUrl } from "../../../../_metronic/helpers"
import { useNavigate } from "react-router-dom"

export const Buttons = (props: any) => {
    const navigate = useNavigate()
    return (
        <div className="d-flex">
            <a
                className={'btn btn-sm btn-flex fw-bold btn-primary'}
                onClick={() => props.setOpenDialog(true)}
            >
                <FormattedMessage id="DOCUMENTS.BTNS.CREATE_FOLDER" />
            </a>
            <a
                onClick={() => navigate('/upload-document', {
                    state: {
                        currentCommunity: props.currentCommunity,
                        currentParent: props.currentParent,
                        folderTree: props.folderTree
                    }
                })}
                className={'ms-4 btn btn-sm btn-flex fw-bold btn-primary'}
            >
                <FormattedMessage id="DOCUMENTS.BTNS.UPLOAD_FILE" />
            </a>
            <a
                onClick={() => navigate('/create-document', {
                    state: {
                        currentCommunity: props.currentCommunity,
                        currentParent: props.currentParent,
                        folderTree: props.folderTree
                    }
                })}
                className={'ms-4 btn btn-sm btn-flex fw-bold btn-primary'}
            >
                <FormattedMessage id="DOCUMENTS.BTNS.CREATE_FILE" />
            </a>
        </div>
    )
}